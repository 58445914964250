<template>
  <v-row justify="center">
    <v-col cols="10">
      <v-card outlined class="main-border">
        <v-row justify="center" class="mt-4 mb-2">
          <v-img
            src="https://www.freeiconspng.com/thumbs/credit-card-icon-png/credit-card-black-png-0.png"
            max-width="100"
            max-height="100"
          >
          </v-img>
          <v-col cols="8">
            <h1 v-if="paymentType == 'NEW_MEMBER'">
              Inscripción como nuevo miembro
            </h1>
            <h1 v-else>Renovación de membresía</h1>
            <p>
              Recuerda que primero debes cubrir tu pago nacional y una vez
              realizado de forma exitosa podrás realizar tu pago local. <br />
              Una vez cubiertos ambos pagos, se habilitará tu tablero.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-1 mb-4">
          <v-col cols="5">
            <v-card
              outlined
              :class="!isActivePaymentNational ? 'disabled-color' : ''"
              class="border"
            >
              <v-row justify="center" class="ma-1">
                <h3 class="text-center">Pago Nacional</h3>
                <v-col cols="10">
                  <p class="text-caption">
                    Da click en el botón para
                    proceder a pagar
                  </p>
                </v-col>
                <v-btn
                  color="primary"
                  class="white--text mb-2"
                  rounded
                  :disabled="!isActivePaymentNational"
                  :to="`/member/payment/${user.affiliation.national._id}`"
                >
                  Realizar Pago
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card
              outlined
              :class="!isActivePaymentLocal ? 'disabled-color' : ''"
              class="border"
            >
              <v-row justify="center" class="ma-1">
                <h3 class="text-center">Pago Local</h3>
                <v-col cols="10">
                  <p class="text-caption">
                    Da click en el botón para
                    proceder a pagar
                  </p>
                </v-col>
                <v-btn
                  color="primary"
                  class="white--text mb-2"
                  rounded
                  :disabled="!isActivePaymentLocal"
                  :to="`/member/payment/${user.affiliation.local._id}`"
                >
                  Realizar Pago
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { isActivePaymentNationalH, isActivePaymentLocalH } from "@/common/hook/useHelper.js";

export default {
  components: {},
  data() {
    return {
      affiliation: {},
      isActivePaymentNational: false,
      isActivePaymentLocal: false
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    paymentType() {

      if (this.user.affiliation.local && this.user.affiliation.local.details) {
        if (this.user.affiliation.local.details.status === "ACTIVE") {
          return "ACTIVE_MEMBER";
        }
        return "INACTIVE_MEMBER";
      }
      return "NEW_MEMBER";
    },
  },
  methods: {
    isActivePaymentNationalH,
    isActivePaymentLocalH,
    ...mapActions("user", ["realoadUserData"]),
    reload() {
      this.realoadUserData({ email: this.user.email });
    },
  },
  watch:{
    user(val){
      this.affiliation = this.user.affiliation
    },
    affiliation(val){
      if(val){
        this.isActivePaymentNational = this.isActivePaymentNationalH(val)
        this.isActivePaymentLocal = this.isActivePaymentLocalH(val)
      }
    }
  },
  async mounted() {
    this.reload()
    this.affiliation = this.user.affiliation
  },
};
</script>

<style>
.main-border {
  border: solid #010101 !important;
  border-width: 8px !important;
  border-radius: 10px !important;
}
.border {
  border: solid #010101 !important;
  border-width: 4px !important;
  border-radius: 10px !important;
}
.disabled-color {
  opacity: 0.7;
}
</style>